.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .mainPort {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  /* Build Campaign Screen */

.show {
  display: block;
}

.hide {
  display: none;
}

.div-campaign-screen-title {
  Width: 400px;
  Height: 24px;
  padding-top: 20px;
  Top: 32px;
  Left: 288px;
  Font-family: Roboto;
  Font-style: Regular;
  Font-size: 26px;
  Line-height: 24px;
  Line-height: 79%;
  text-align: Left;
  Vertical-align: Top;
  Fill: Solid #000000
}

.div-client-label-campaign-list {
  position: absolute;
  top: 80px;
  padding-top: 20px;
  width: 100px;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #000000;
}

.div-client-label {
  position: absolute;
  top: 32px;
  right: 510px;
  width: 100px;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #000000;
}
.div-client-name-campaign-list {
  position: absolute;
  top: 90px;
  left:310px;
  width: 300px;

}
.div-client-name-build-your-ad {
  position: absolute;
  top: 0px;
  left:10px;
  width: 400px; 
}

select {
  height: 40px;
  flex: 0 0 200px;
  margin-left: 10px;
}

.div-client-name {
  position: absolute;
  right:270px;

}
.div-campaign-date {
  position: absolute;
  right: -30px;
  width: 300px;
}

.tab-page-campaign{
  /*position: absolute;
  width: 87px;
  height: 24px;
  left: 293px;
  top: 73px;*/

  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #000000;
}

.tab-page-new-campaign{
  /*position: absolute;
  width: 111px;
  height: 24px;
  left: 412px;
  top: 73px;*/

  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  color: #667EEA;

}

.label-campaign-form-field{
  height: 24px;
  /* font-family: 'HelveticaNeue'; */
  font-style: normal;
  /* font-weight: 500; */
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;

  color: #000000;
}

.textbox-character-count{
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */

  text-align: right;

  color: #999999;
}

