@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error {
    color: red;
    font-family: 'Helvetica Neue';
    font-weight:bold;
}

.display{
    visibility: "display"
}

.hidden{
    visibility: "hidden";
}

.left{
    width: 300px; 
    height: 100vh; 
    float: left
}
.right{
    margin-left: 300px; 
    flex: 1;
}

.grid-container {
    position: absolute;
    top: 160px;
    width: 300px;
    display: grid;
    grid-template-columns: auto auto auto auto ;
}

.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 10px solid white;
    text-align: center;
  }

  .clone-grid
  {
    display: grid;
    grid-template: auto/ auto auto auto;
    grid-gap: 1px;
    padding: 1px;
    width: 350px;
    text-align: center;
 }
 
 .clone-grid-item{
    text-align: left;
    padding:5px;
    width: 155px
 }
 .clone-grid-item-arrow{
    text-align: center;
    padding:5px;
    padding-top: 15px;
    width: 35px
  }


.campaign-month-box{
    width:206px; 
    box-sizing: border-box;
    background: #FFFFFF;
    border: #D9D9D9 solid 1px;
}

.campaign-month-header-box {
    height: 30px;
    background: #F4F4F5;
    border-radius: 4px 4px 0px 0px;
}

.campaign-month-header-text-box {
    font-family: 'Helvetica Neue';
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    justify-content: center;
    color: #000000;
    
}

.campaign-month-body-box {
    width:206px;
    height: 126px;
    text-align: center;
    border-radius: 4px;
}
.campaign-month-body-section-box{
    padding-top:15px;
    height: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 20px;
}

.campmaign-month-status{
    font-family: 'Helvetica Neue';
    font-style: bold;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #3F3F46;
    border-radius: 4px;
}

.create-campaign-button{
    flex-direction: row;
    justify-content: center;
    padding: 5px 16px;
    width: 97px;
    height: 31px;
    background: #27AE60;
    border-radius: 999px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.clone-button{
    flex-direction: row; 
    align-items: center;
    width: 71px;
    height: 31px;
    color:white;
    background: #00B2FF;
    border-radius: 999px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.flight-dates{
    font-family: 'Helvetica Neue';
    font-style: bold;
    font-weight:400;
    font-size: 13px;
    line-height: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 30px;
    color: #3F3F46;
}
h4{
    height: 24px;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #000000;
}
.image-editor{
    position: absolute;
    top: 286px;
    left: 650px;
}

.progress-circle{
    z-index: 1;
    position: relative;
    left: 40px;
    top: -41px;
    width: 220px;
    height: auto;
}

.no-campaign-companies-found{
    font-weight:400;
    font-size: 16px !important;
    line-height: 150%;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
    padding-top: 2px;
    padding-left: 7px;
    color: #BDBDBD;;
}

.create-campaign-button-text{
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.campaign-header {
    max-width: 1280px;
    min-width: 900px;
    overflow: hidden;
    position: relative;
    left: -10px
}

.campaign-header-box {
    /* text-align: center;
    justify-content: center;
    margin: 0 auto;
    border: 3px solid red; */

    box-sizing: border-box;
    width: 24%;
    height: 155px;
    float: left;
    margin-left: 1%;
    position: relative;
    top:10px;
}    

.campaign-header-first-box{

    position: relative;
    /* left: -10px */
}

.companies-list{
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #BDBDBD;;
    height: 150px;
    overflow-y: auto;
}

select-top-label {
    margin-left:"20px";
    line-height: "25px";
    font-style: "normal";
    font-weight: "500";
    font-size: "16px";
}

select-left-label {
    margin-right:"10px";
    font-style: "normal";
    font-weight: "500";
    font-size: "16px";
}

select-form-field  { 
    width: "300px";
    height: "40px";
    border: "1px solid #999";
}

editable-company-name {
    font-weight: 500;
    background-color: blue !important;
    border: "0px solid black" !important;
}

/* File Upload CSS */
#form-file-upload {
    padding-top: 10px;
    height: 40px;
    width: 220px;
    max-width: 100%;
    text-align: center;
    position: relative;
  }
  
  #input-file-upload {
    display: none;
  }
  
  #label-file-upload {
    height: 180%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  }
  
  #label-file-upload.drag-active {
    background-color: #52d2e6;
  }
  
  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }
  
  .upload-button:hover {
    text-decoration-line: underline;
  }
  
  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }